

















































































































































































































































































import LoginModel from "./index";
export default LoginModel;
import RegisterModel from "./index";
// export default{
//   inject:['reload'],
//   methods:{
//     reload(){
//       this.reload()
//     }
//   },
//   created(){
//     this.reload()
//   }
// }

// export default RegisterModel;
