import { Component, Vue, Model,Watch } from "vue-property-decorator";
import { phone, sginPas,phoneCode } from "@/utils/rule";
import { getuser, login, agreementApi, about ,getLoginCaptcha } from "@/utils/api";
import { Loading } from "element-ui";


import axios, { AxiosRequestConfig } from "axios";
/**
 * 定义当前数据
 */
 interface Validate {
  username: string;
  password: string;
  phone: string;
  captcha:  string;
}


/**
 * 定义当前表单验证
 */
interface LoginRulesFun {
  username: {
    required: boolean;
    trigger: string;
    validator: (rule: any, value: string, callback: () => {}) => void;
  };
  password: {
    required: boolean;
    trigger: string;
    validator: (rule: any, value: string, callback: () => {}) => void;
  };

}

@Component
export default class LoginModel extends Vue {
  private loginRules = {
    username: [
      { required: true, trigger: "blur", validator: phone[0].validator }
    ],
    password: [
      { required: true, trigger: "blur", validator: sginPas[0].validator }
    ],
    phone: [
      { required: true, trigger: "blur", validator: phone[0].validator }
    ],
    captcha: [
      { required: true,
        trigger: "blur",
        validator: phoneCode[0].validator }
    ],
  };

  // 是否登录了
  private islogin = false;
  msg?: string = "";
  // 验证码登录
  verification:boolean = false;
  // 账号登录
  account:boolean = true;
  checked:boolean = false;

  // 内容输入格式正确  登录盒子变色允许登录
  isLogin:boolean = false;
  show = false;
  // 密码登录
  private loginForm = {
    // 13722259888
    // 123456
    username: localStorage.getItem("moible") ? localStorage.getItem("moible") : "",
    password: "",
    scope: "write",
    grant_type: "password",
    client_secret: JSON.parse(process.env.VUE_APP_MY_PARMA).password,
    client_id: JSON.parse(process.env.VUE_APP_MY_PARMA).username
  };
  // 验证码登录
  private captchaLoginForm = {
    // 13722259888
    // 123456
    username: localStorage.getItem("moible") ? localStorage.getItem("moible") : "",
    captcha: "",
    scope: "write",
    grant_type: "password",
    client_secret: JSON.parse(process.env.VUE_APP_MY_PARMA).password,
    client_id: JSON.parse(process.env.VUE_APP_MY_PARMA).username
  };
  // 倒计时初始化
  private phonetime = {
      time: "获取验证码",
      btndisable: false
  };
  mounted() {
    // agreementApi("REGISTER").then(res => {
    //   this.msg = res.content;
    // });
  }
  @Watch('loginForm',{deep:true})
  onTypeChange(newVal:any,oldVal:any){
          if (newVal.username && newVal.password) {
            (this.$refs.loginForm as any).validate((valid: boolean) => {
              if (valid) {
                this.isLogin = true
              }else{
                this.isLogin = false
              }
            })
          }else{
                this.isLogin = false
          }
  }
  @Watch('captchaLoginForm',{deep:true})
  captchaLoginChange(newVal:any,oldVal:any){
          if (newVal.username && newVal.captcha) {
            (this.$refs.captchaLogin as any).validate((valid: boolean) => {
              if (valid) {
                this.isLogin = true
              }else{
                this.isLogin = false
              }
            })
          }else{
                this.isLogin = false
          }
  }


  getCode(captchaLogin:any){
    (this.$refs[captchaLogin] as any).validateField("username", (phoneError:string) => {
      //验证手机号码是否正确
      if (!phoneError) {
        // 手机号码正确
        if (!this.phonetime.btndisable) {
          this.phonetime.btndisable = true;
          let lastTime = 60;
          const timer = setInterval(() => {
            if (lastTime > 0) {
              this.phonetime.time = lastTime + "s";
              lastTime--;
            } else {
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            }
          }, 1000);

            getLoginCaptcha(this.captchaLoginForm.username,'LOGIN')
            .then((res:any) => {
              if (res.code != 0) {
                window.clearInterval(timer);
                this.$toast.fail(res.msg)
                this.phonetime.btndisable = false;
                this.phonetime.time = "获取验证码";
              }
            }).catch((err:any)=>{
              window.clearInterval(timer);
              this.phonetime.btndisable = false;
              this.phonetime.time = "获取验证码";
            })
        }
      } else {
        this.$toast(phoneError)
        return false;
      }
    });
  }

  cehckChange(){
    // if (this.account) {
    //     // 账号登录
    //     if (this.loginForm.username && this.loginForm.password &&this.checked ) {
    //       (this.$refs.loginForm as any).validate((valid: boolean) => {
    //         if(valid){
    //           this.isLogin = true
    //         }else{
    //       this.isLogin = false

    //         }
    //       })

    //     }else{
    //       this.isLogin = false
    //     }
    // }else if(this.verification){
    //   // 验证码登录
    //   if (this.captchaLoginForm.username && this.captchaLoginForm.captcha &&this.checked ) {
    //     (this.$refs.captchaLogin as any).validate((valid: boolean) => {
    //       if(valid){
    //         this.isLogin = true
    //       }else{
    //         this.isLogin = false

    //       }
    //     })

    //   }else{
    //     this.isLogin = false
    //   }
    // }
  }
  loginMode(item:any){
        if (item == 'verification') {
          (this.$refs.loginForm as any).resetFields()
            this.account = false
            this.verification = true
          }else{
            (this.$refs.captchaLogin as any).resetFields()
            this.account = true
            this.verification = false
          }
          this.checked = false
  }


  getAgeement(type: string) {
    agreementApi(type).then((res:any) => {
      // debugger;
      this.msg = res.content;
    });
  }

  showrule(type: string) {
    this.show = true;
    this.msg = undefined;
    this.getAgeement(type);
  }
  backbtn() {
    this.$router.push({ name: "home" });
  }

  handleLogin(formName:any,captchaLogin:any) {
      if (!this.isLogin) {
        return false
      }
      if (!this.checked) {
        return this.$toast(`需先同意《${Vue.prototype.GLOBAL}用户注册协议》及《${Vue.prototype.GLOBAL}用户隐私政策》`)
      }
      if (this.account) {
        (this.$refs[formName] as any).validate((valid: boolean) => {

          if (valid) {
            this.islogin = true;
            const auth = {
              username: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
              password: JSON.parse(process.env.VUE_APP_MY_PARMA).password
            };
            const headers_ = {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              }
            };
            const transformRequest = [
              function(data: { [x: string]: string | number | boolean }) {
                let ret = "";
                for (const it in data) {
                  ret +=
                    encodeURIComponent(it) +
                    "=" +
                    encodeURIComponent(data[it]) +
                    "&";
                }
                return ret;
              }
            ];
            let copyUserName = this.loginForm.username;
            const loginUsername = { username: copyUserName += "|MOBILE_PWA" };
            const copyobj = Object.assign({}, this.loginForm, loginUsername);
            login(copyobj, { auth, transformRequest, headers_ })
              .then((res:any) => {
                if (res.access_token) {
                  console.log("res"+res);
                  localStorage.setItem("token", res.access_token);
                  localStorage.setItem("refresh_token", res.refresh_token);
                  localStorage.setItem("isSevrice",'1');

                  // localStorage.setItem("sela",getuser.getuser().then(res => {res.uid}));
                  // localStorage.setItem("sela1","粤恒通（北京天雅）检测中心");
                  // localStorage.setItem("sela2" ,"北京市东城区 法华寺街136号天雅珠宝城1楼1076");

                  const redirect_ = this.$route.query.redirect;
                  const userName = String(this.loginForm.username).split("|")[0];
                  localStorage.setItem("moible", userName);


                  // todo
                  getuser().then((res:any) => {
                    // console.log('111',res);

                    localStorage.setItem("userinfo", JSON.stringify(res));
                    // Vue.prototype.fishkefu.Config(JSON.parse( localStorage.getItem('userinfo')))
                    if (redirect_) {
                      // redirect_ = decodeURIComponent(redirect_)
                      // this.$router.push({ name: redirect_.replace('/','')});
                      this.$router.push({ name: "pricing" });
                    } else {
                      this.$router.push({ name: "person" });
                    }

                  });


                  // if (redirect_) {
                  //   // this.$router.push({ name: redirect_.replace('/','')});
                  //   this.$router.push({ name: "pricing" });
                  // } else {
                  //   this.$router.push({ name: "person" });
                  // }
                } else {
                  this.islogin = false;
                }
              })
              .catch((err:any) => {
                console.log("err:"+err);
                if (err.response.status == 401) {
                  this.$toast.fail("手机号未注册");
                }
                if (err.response.status == 400) {
                  this.$toast.fail("账户名或密码错误");
                }
                if (err.response.status == 403) {
                  this.$toast.fail("账号异常,请联系客服");
                }
                this.islogin = false;
              });
            // registerbox.account(this.RegisterForm).then(res=>{
            //   this.$router.push({name:'login'})
            // })
          }
        });
      }else if (this.verification) {
        (this.$refs[captchaLogin] as any).validate((valid: boolean) => {
          if (valid) {
            this.islogin = true;
            const auth = {
              username: JSON.parse(process.env.VUE_APP_MY_PARMA).username,
              password: JSON.parse(process.env.VUE_APP_MY_PARMA).password
            };
            const headers_ = {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              }
            };
            const transformRequest = [
              function(data: { [x: string]: string | number | boolean }) {
                let ret = "";
                for (const it in data) {
                  ret +=
                    encodeURIComponent(it) +
                    "=" +
                    encodeURIComponent(data[it]) +
                    "&";
                }
                return ret;
              }
            ];
            let copyUserName = this.captchaLoginForm.username;
            const loginUsername = { username: copyUserName += "|MOBILE_PWA" };
            const copyobj = Object.assign({}, this.captchaLoginForm, loginUsername);
            login(copyobj, { auth, transformRequest, headers_ })
              .then((res:any) => {
                if (res.access_token) {
                  localStorage.setItem("token", res.access_token);
                  localStorage.setItem("refresh_token", res.refresh_token);
                  localStorage.setItem("isSevrice",'1');

                  // localStorage.setItem("sela",getuser.getuser().then(res => {res.uid}));
                  // localStorage.setItem("sela1","粤恒通（北京天雅）检测中心");
                  // localStorage.setItem("sela2" ,"北京市东城区 法华寺街136号天雅珠宝城1楼1076");

                  const redirect_ = this.$route.query.redirect;
                  const userName = String(this.loginForm.username).split("|")[0];
                  localStorage.setItem("moible", userName);


                  // todo
                  getuser().then((res:any) => {

                    localStorage.setItem("userinfo", JSON.stringify(res));
                    // Vue.prototype.fishkefu.Config(JSON.parse( localStorage.getItem('userinfo')))
                    if (redirect_) {
                      // redirect_ = decodeURIComponent(redirect_)
                      // this.$router.push({ name: redirect_.replace('/','')});
                      this.$router.push({ name: "pricing" });
                    } else {
                      this.$router.push({ name: "person" });
                    }

                  });


                  // if (redirect_) {
                  //   // this.$router.push({ name: redirect_.replace('/','')});
                  //   this.$router.push({ name: "pricing" });
                  // } else {
                  //   this.$router.push({ name: "person" });
                  // }
                } else {
                  this.islogin = false;
                }
              })
              .catch((err:any) => {
                console.log("err:"+err);

                if (err.response.status == 400) {
                  this.$toast.fail("账户名或密码错误");
                }
                this.islogin = false;
              });
            // registerbox.account(this.RegisterForm).then(res=>{
            //   this.$router.push({name:'login'})
            // })
          }
        })
      }

  }
}
